(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/clx/assets/javascripts/clx.js') >= 0) return;  svs.modules.push('/components/lb-utils/clx/assets/javascripts/clx.js');
"use strict";

const clx = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return [...args].reduce((acc, value) => {
    if (typeof value === 'string' || typeof value === 'number') {
      return [...acc, value];
    }
    if (Array.isArray(value)) {
      return [...acc, ...value.map(v => clx(v))];
    }
    if (typeof value === 'object') {
      return [...acc, ...Object.entries(value).reduce((a, _ref) => {
        let [k, v] = _ref;
        return v ? [...a, k] : a;
      }, [])];
    }
    return acc;
  }, '').filter(Boolean).join(' ');
};
setGlobal('svs.components.lbUtils.clx', clx);

 })(window);